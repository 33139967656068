import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="dialog"
export default class extends Controller<HTMLDialogElement> {
  connect() {
    this.showModal();

    this.element.addEventListener("turbo:before-morph-element", this.beforeMorph.bind(this));
  }

  disconnect() {
    this.remove();

    this.element.removeEventListener("turbo:before-morph-element", this.beforeMorph.bind(this));
  }

  closeOutside(event: { target: HTMLDialogElement }) {
    if (event.target === this.element) {
      this.close();
    }
  }

  beforeMorph(event: Event) {
    if (this.element.open) {
      event.preventDefault();
    }
  }

  close() {
    this.element.close();
    this.remove();
  }

  private showModal() {
    this.element.showModal();
  }

  private remove() {
    this.element.remove();
  }
}
