import { Controller } from "@hotwired/stimulus";
import {
  parseTagParams,
  formatTagValueWithOperator,
} from "app/components/analytics/shared/tagHelpers";
import { debounce } from "lodash";

// Connects with data-controller="build-tests-filter-dialog"
export default class extends Controller<HTMLElement> {
  static targets = ["tagKey", "tagValue", "operator", "pill", "emptyState"];

  declare tagKeyTarget: HTMLInputElement;
  declare tagValueTarget: HTMLInputElement;
  declare operatorTarget: HTMLSelectElement;
  declare pillTargets: HTMLElement[];
  declare hasPillTargets: boolean;
  declare tagKeys: string[];
  declare emptyStateTarget: HTMLElement;
  declare hasEmptyStateTarget: boolean;

  initialize(): void {
    this.tagKeys = [];
  }

  pillTargetConnected(element) {
    this.tagKeys.push(element.dataset.key);

    element.addEventListener("click", this.tagKeyChange.bind(this));
  }

  submit(event) {
    event.preventDefault(); // Prevent default form submission

    const form = this.element as HTMLFormElement;
    const url = new URL(form.action);
    const params = new URLSearchParams(url.search);
    const tags = parseTagParams(params.get("tags"));
    const tagValue = formatTagValueWithOperator(
      this.tagValueTarget.value,
      this.operatorTarget.value,
    );

    const newTags = tags.includeByKey(this.tagKeyTarget.value, tagValue);

    params.set("tags", newTags.toQueryString());
    url.search = params.toString();

    const turboFrame = this.element.closest("turbo-frame[data-executions]");

    if (turboFrame) {
      turboFrame.setAttribute("src", url.toString());
    }
  }

  tagKeyChange(event) {
    event.preventDefault();
    this.tagKeyTarget.value = event.target.dataset.key;
  }

  searchTagKeys({ target: { value: searchInput } }) {
    debounce(() => {
      this.filterTagKeys(searchInput);
    }, 300)();
  }

  filterTagKeys(searchInput) {
    if (this.hasPillTargets) {
      return;
    }

    const searchedKeys = this.tagKeys.filter((key) => key.includes(searchInput));

    if (this.hasEmptyStateTarget) {
      this.emptyStateTarget.hidden = searchedKeys.length !== 0;
    }

    this.pillTargets.forEach((element) => {
      const key = element.dataset.key;

      if (key) {
        element.hidden = !searchedKeys.includes(key);
      }
    });
  }
}
