import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLElement> {
  static targets = ["source", "initialContent", "notice", "button"];
  static classes = ["hidden"];
  static values = { isIconOnly: Boolean };

  declare sourceTarget: HTMLInputElement;
  declare initialContentTarget: HTMLElement;
  declare noticeTarget: HTMLElement;
  declare buttonTarget: HTMLButtonElement;
  declare hiddenClass: string;
  declare isIconOnlyValue: boolean;

  async copy(event: Event) {
    event.preventDefault();

    try {
      await navigator.clipboard.writeText(this.sourceTarget.value);
      this.revealNotice();
      setTimeout(() => this.reset(), 2000);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  }

  private revealNotice() {
    if (this.isIconOnlyValue) {
      this.buttonTarget.setAttribute("aria-label", this.element.dataset.noticeText as string);
      this.toggleContent();
    } else {
      this.toggleContent();
    }
  }

  private reset() {
    if (this.isIconOnlyValue) {
      this.buttonTarget.setAttribute("aria-label", this.element.dataset.text as string);
      this.toggleContent();
    } else {
      this.toggleContent();
    }
  }

  private toggleContent() {
    this.initialContentTarget.classList.toggle(this.hiddenClass);
    this.noticeTarget.classList.toggle(this.hiddenClass);
  }
}
