import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="dismissible"
export default class extends Controller<HTMLDialogElement> {
  static values = { cookieKey: String };

  declare cookieKeyValue;

  connect() {
    // If there is no cookie at load, check localStorage in case
    // the user has dismissed the component previously and cleared cookies
    if (!document.cookie.includes(this.cookieKeyValue)) {
      const value = localStorage.getItem(this.cookieKeyValue);
      if (value) {
        this.updateRailsCookie();
      }
    }
  }

  updateRailsCookie() {
    this.element.remove();
    // save value to rails accessible cookie for 1 year
    document.cookie = `${this.cookieKeyValue}=true;path=/;max-age=31536000`;
    localStorage.setItem(this.cookieKeyValue, "true");
  }
}
