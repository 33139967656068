import { Controller } from "@hotwired/stimulus";

/**
 * This controller is used to validate a collection of checkboxes. It can be used to ensure that at least one checkbox is checked.
 * It does this by adding a custom validity message to the first checkbox in the collection.
 *
 * Example usage:
 * <fieldset data-controller="collection-checkboxes" data-required="true">
 *   <legend>Choose your favorite colors</legend>
 *    <label><input type="checkbox" name="colors[]" value="red"> Red</label>
 *    <label><input type="checkbox" name="colors[]" value="green"> Green</label>
 *    <label><input type="checkbox" name="colors[]" value="blue"> Blue</label>
 * </fieldset>
 */
export default class extends Controller<HTMLFieldSetElement> {
  declare checkboxes: NodeListOf<HTMLInputElement>;

  connect() {
    this.checkboxes = this.element.querySelectorAll("input[type=checkbox]");
    this.element.addEventListener("change", this.validate.bind(this));
    this.validate(); // Validate on connect to ensure the initial form state is correct.
  }

  disconnect() {
    this.element.removeEventListener("change", this.validate.bind(this));
  }

  validate() {
    if (this.element.dataset.required === "true") {
      const checked = Array.from(this.checkboxes).some((checkbox) => checkbox.checked);

      const firstCheckbox = this.checkboxes[0];
      if (firstCheckbox) {
        firstCheckbox.setCustomValidity(checked ? "" : "Please select at least one option.");
      }
    }
  }
}
