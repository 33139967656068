import { Controller } from "@hotwired/stimulus";

// Connects with data-controller="dialog"
export default class extends Controller {
  static targets = ["stylesheetCheckbox"];
  declare stylesheetCheckboxTargets;

  static classes = ["display"];
  declare displayClass;

  connect() {
    this.stylesheetCheckboxTargets.forEach((target) => {
      const stylesheet = target.dataset.stylesheetid;
      target.checked = !stylesheet.disabled;
    });
  }

  display() {
    this.element.classList.toggle(this.displayClass);
  }

  toggleDisabled(event) {
    const element = document.getElementById(event.target.dataset.stylesheetid) as HTMLStyleElement;
    element.disabled = event.target.checked === false;
  }
}
